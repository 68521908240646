import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useUserAuth } from '../../Context/UserAuthContext';

function OnboardingStart() {
  let { user } = useUserAuth();

  return (
    <Wrapper>
      <section>
        <div className="flex flex-col lg:flex-row w-full">
          <div className="flex justify-center w-full lg:w-1/2">
            <img
              className="object-cover w-full lg:h-[calc(100vh-80px)] h-[300px] lg:w-full"
              src="https://firebasestorage.googleapis.com/v0/b/pantry-3543e.appspot.com/o/albums%2FTdRzdybtgEaAdLd2FjJLFYJLvsR2%2FMisquamicut%2FMisquamicut%20surf%2FIMG_8047.jpeg?alt=media&token=6d31b6de-b4f6-4faa-87f1-7f4062f68f05"
              alt="Onboarding"
            />
          </div>

          <div className="flex flex-col items-center justify-center mt-6 lg:items-start lg:w-1/2 lg:mt-0 lg:ml-0 p-8">
            <h2 className="text-2xl font-semibold tracking-tight text-gray-800 lg:text-3xl dark:text-white">
              Start booking clients on FLMR
            </h2>

            <p className="block max-w-2xl mt-4 text-gray-500 dark:text-gray-300">
              We're stoked you want to start booking surf photo sessions through FLMR. Just so we get this right, we just need to gather a little info about what you do, your price, where you want to photograph and a few other things. This should only take about 5 minutes and you'll be ready to start accepting reservations.
            </p>

            <div className="mt-6 flex flex-col sm:flex-row sm:-mx-2">
              <Link
                to={`/onboarding/${user.uid}`}
                className="inline-flex items-center justify-center w-full px-4 py-2.5 mb-4 text-sm text-white bg-[#1C2127] rounded-lg shadow-sm transition-colors duration-300 hover:bg-[#1c2127c0] focus:ring focus:ring-gray-300 focus:ring-opacity-80 sm:w-auto sm:mx-2 sm:mb-0"
              >
                <span className="mr-2">Start onboarding</span>
                <svg
                  className="w-3 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.3 15.3C7.11667 15.1167 7.02067 14.8833 7.012 14.6C7.004 14.3167 7.09167 14.0833 7.275 13.9L12.175 8.99999H1C0.716667 8.99999 0.479 8.90399 0.287 8.71199C0.0956668 8.52065 0 8.28332 0 7.99999C0 7.71665 0.0956668 7.47899 0.287 7.28699C0.479 7.09565 0.716667 6.99999 1 6.99999H12.175L7.275 2.09999C7.09167 1.91665 7.004 1.68332 7.012 1.39999C7.02067 1.11665 7.11667 0.883321 7.3 0.699987C7.48333 0.516654 7.71667 0.424988 8 0.424988C8.28333 0.424988 8.51667 0.516654 8.7 0.699987L15.3 7.29999C15.4 7.38332 15.471 7.48732 15.513 7.61199C15.5543 7.73732 15.575 7.86665 15.575 7.99999C15.575 8.13332 15.5543 8.25832 15.513 8.37499C15.471 8.49165 15.4 8.59999 15.3 8.69999L8.7 15.3C8.51667 15.4833 8.28333 15.575 8 15.575C7.71667 15.575 7.48333 15.4833 7.3 15.3Z" fill="white"/>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  font-family: 'Montserrat';
  border-radius: 4px;
  width: 100vw;

  img {
    border-radius: 3px;
  }
`;

export default OnboardingStart;
